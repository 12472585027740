import { ScriptWidget } from '@src/interfaces';
import React, { useCallback, useEffect } from 'react';
import ComponentTitle from '@src/components/ComponentTitle';

const ScriptLoader = (props: ScriptWidget ) => {
  const injectScript = useCallback(() => {
    // eslint-disable-next-line global-require
    const postscribe = require('postscribe');
    postscribe(
      `#${props.id}`,
      props.html,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    injectScript();
  }, [injectScript]);

  return (
    <div id={props.id}>
      <ComponentTitle title={props.title}/>
    </div>
  )
}

export default ScriptLoader;
